import TableRegisteredStudentsNoSelectable from "@/components/layout/TableRegisteredStudentsNoSelectable/tableRegisteredStudentsNoSelectable.vue"
import PhysiologyRepository from '@/shared/http/repositories/socialProject/physiology'
import PhysiologySprint from "@/views/physiology/components/physiologySprint/physiologySprint.vue"
import PhysiologyYoyo from "@/views/physiology/components/physiologyYoyo/physiologyYoyo.vue"
import PhysiologyJump from "@/views/physiology/components/physiologyJump/physiologyJump.vue"
import TeacherRepository from '@/shared/http/repositories/socialProject/teacher'
import ClassRepository from '@/shared/http/repositories/socialProject/class'
import BreadCrumb from '@/components/layout/BreadCrumb/breadCrumb.vue'
import classCriteria from '@/shared/models/criteria/classcriteria'
import { loading, toast } from '@/shared/utils/UIHelper'
import PhysiologyModel from '@/shared/models/physiology'
import { IsSuccessCode } from '@/shared/utils/API'
import GuidUtils from '@/shared/utils/guid'

export default {
  components: {
    [TableRegisteredStudentsNoSelectable.name]: TableRegisteredStudentsNoSelectable,
    [PhysiologySprint.name]: PhysiologySprint,
    [PhysiologyJump.name]: PhysiologyJump,
    [PhysiologyYoyo.name]: PhysiologyYoyo,
    [BreadCrumb.name]: BreadCrumb
  },

  data: () => ({
    physiologyModel: new PhysiologyModel(),
    classCriteria: new classCriteria(),
    onHideExportButton: false,
    disableAllInputs: false,
    onShowModalQuestion: false,
    getStudentsId: true,
    showTableSprint: false,
    showTableJump: false,
    showTableYoyo: false,
    optionsTeachers: [],
    optionsClass: [],
    studentsId: [],
    loadTableSprint: {},
    onLoadTable: {},
    loadTableJump: {},
  }),

  computed: {
    breadCrumb() {
      return {
        breadcrumbItems: [
          {
            text: 'Dashboard',
            href: this.$router.resolve({ name: 'Dashboard' }).href
          },
          {
            text: 'Fisiologia',
            href: this.$router.resolve({ name: 'physiology' }).href
          },
          {
            text: 'Temporada',
            active: true
          }
        ]
      }
    },

    checkClassId() {
      const hasClassId = this.physiologyModel.classId

      if (hasClassId){
        return this.physiologyModel.classId
      }
    },

    disableInputs() {
      const isNew = !this.$route.params.id || this.$route.params.id == GuidUtils.isEmpty

      if (!isNew) {
        return true
      }
      return false
    }
  },

  created() {
    this.$validator.reset();
    const isNew = !this.$route.params.id || this.$route.params.id == GuidUtils.isEmpty
    
    this.getAllTeacher()
    
    if (!isNew) {
      loading.push()
      this.getClass()
      this.getById()
      loading.pop()
    }
  },

  methods: {
    getById() {
      loading.push()
      PhysiologyRepository.GetById(this.$route.params.id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.physiologyModel = new PhysiologyModel(res.data.data)
          this.$refs.registeredStudentNoSelectable.showAction = false

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar esta turma', 'ERRO')
        })
    },
    getClass() {
      const isNew = GuidUtils.isEmpty(this.physiologyModel.id)

      if (!this.classCriteria.teacherId && !isNew) {
        this.optionsClass = []
        return
      }

      loading.push()
      ClassRepository.GetAll(this.classCriteria)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.optionsClass = res.data.data
          this.$refs.registeredStudentNoSelectable.showAction = false
          loading.pop()
          return Promise.resolve()
        })
        .catch((err) => {
          loading.pop()
          console.log(err)
          toast.error(err, 'ERRO')
        })
    },

    getAllTeacher() {
      loading.push()
      TeacherRepository.GetAll()
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.optionsTeachers = res.data.data
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar professores', 'ERRO')
        })
    },

    onSave() {
      this.physiologyModel.studentsIds = this.studentsId

      if (this.physiologyModel.studentsIds.length == 0) {
        toast.info('Não é possível continuar. Turma sem aluno cadastrado!', 'AVISO')
        this.physiologyModel.classId = null
        this.physiologyModel.name = null
        this.physiologyModel.season = null
        this.physiologyModel.jumpLiterature = null
        this.physiologyModel.sprintLiterature = null
        this.physiologyModel.yoyoLiterature = null
        this.physiologyModel.jumpLiteratureName = null
        this.physiologyModel.sprintLiteratureName = null
        this.physiologyModel.yoyoLiteratureName = null
        this.physiologyModel.studentsIds = []
        return
      }

      this.$validator.validateAll().then(isValid => {
        if (!isValid) {
          toast.error('É necessário preencher os campos obrigatórios antes de continuar', 'ERRO')
          return
        }

      const isNew = GuidUtils.isEmpty(this.physiologyModel.id)

      this.physiologyModel.name = this.physiologyModel.name.toUpperCase()
      this.physiologyModel.jumpLiteratureName = this.physiologyModel.jumpLiteratureName.toUpperCase()
      this.physiologyModel.sprintLiteratureName = this.physiologyModel.sprintLiteratureName.toUpperCase()
      this.physiologyModel.yoyoLiteratureName = this.physiologyModel.yoyoLiteratureName.toUpperCase()

        if (isNew) {
          this.onSaveCreate()
          return
        }

        this.onSaveEdit()
      })
    },

    onSaveCreate() {
      loading.push()
      PhysiologyRepository.Create(this.physiologyModel)
        .then(result => {
          if (!IsSuccessCode(result)) return Promise.reject()

          toast.success('Registro salvo com sucesso', 'SUCESSO')
          this.physiologyModel.id = result.data.data.id
          this.onLoadTable = result.data.data
          this.onShowModalQuestion = true
          loading.pop()
        })
        .catch(() => {
          toast.error('Não foi possível salvar registro.', 'ERRO')
          loading.pop()
        })
    },

    onSaveEdit() {
      loading.push()
      PhysiologyRepository.Update(this.physiologyModel)
        .then(result => {
          if (!IsSuccessCode(result)) return Promise.reject()

          toast.success('Registro alterado com sucesso', 'SUCESSO')
          this.backTo()
          loading.pop()
        })
        .catch(() => {
          toast.error('Não foi possível editar registro.', 'ERRO')
          loading.pop()
        })
    },

    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },

    onStudentsId (ids) {
      this.studentsId = ids
    },

    backTo() { 
      this.$router.go(-1)
    },

    closeTable () {
      this.showTableSprint = false
      this.showTableJump = false
      this.showTableYoyo = false
      this.loadTableSprint = {}
      this.loadTableYoyo = {}
      this.loadTableJump = {}
      this.onLoadTable = {}
      this.backTo()
    },

    noTable() {
      this.onShowModalQuestion = false
      this.backTo()
    },

    onShowTableJump() {
      this.onShowModalQuestion = false
      this.showTableJump = true
      this.disableAllInputs = true
      this.loadTableJump = this.onLoadTable
      this.$emit('hideExportButton', this.onHideExportButton)
    },

    onShowTableSprint() {
      this.onShowModalQuestion = false
      this.showTableSprint = true
      this.disableAllInputs = true
      this.loadTableSprint = this.onLoadTable
      this.$emit('hideExportButton', this.onHideExportButton)
    },

    onShowTableYoyo() {
      this.onShowModalQuestion = false
      this.showTableYoyo = true
      this.disableAllInputs = true
      this.loadTableYoyo = this.onLoadTable
      this.$emit('hideExportButton', this.onHideExportButton)
    }
  }
}
